export default [
  "Krabi",
  "Bangkok",
  "Kanchanaburi",
  "Kalasin",
  "Kamphaengphet",
  "Khonkaen",
  "Chanthaburi",
  "Chachoengsao",
  "Chonburi",
  "Chainat",
  "Chaiyaphum",
  "Chumphon",
  "Chiangrai",
  "Chiangmai",
  "Trang",
  "Trat",
  "Tak",
  "Nakhonnayok",
  "Nakhonpathom",
  "Nakhonphanom",
  "Nakhonratchasima",
  "Nakhonsithammarat",
  "Nakhonsawan",
  "Nonthaburi",
  "Narathiwat",
  "Nan",
  "Buriram",
  "Pathumthani",
  "Prachuapkhirikhan",
  "Prachinburi",
  "Pattani",
  "Payutthaya",
  "Phayao",
  "Phangnga",
  "Phatthalung",
  "Phichit",
  "Phitsanulok",
  "Phetchaburi",
  "Phetchabun",
  "Phrae",
  "Phuket",
  "Mahasarakham",
  "Mukdahan",
  "Maehongson",
  "Yasothon",
  "Yala",
  "Roiet",
  "Ranong",
  "Rayong",
  "Ratchaburi",
  "Lopburi",
  "Loei",
  "Lampang",
  "Lamphun",
  "Sisaket",
  "Sakonnakhon",
  "Songkhla",
  "Satun",
  "Samutprakan",
  "Samutsongkhram",
  "Samutsakhon",
  "Sakaeo",
  "saraburi",
  "Singburi",
  "Sukhothai",
  "Suphanburi",
  "Suratthani",
  "Surin",
  "Nongkhai",
  "Nongbualamphu",
  "Angthong",
  "Amnatcharoen",
  "Udonthani",
  "Uttaradit",
  "Uthaithani",
  "Ubonratchathani",
  "Betong",
];
